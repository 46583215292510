// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: #666666; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
	@extend .linkHover;

	&:after {
		@include breakpoint($break, max) {
			display: none;
		}
	}
}
%buttonHover {
	@include breakpoint($break, max) {
		// Link Hover
		color: $light;
		background-color: $primary;
	}
}
%buttonActive {
	color: $primary;

	@include breakpoint($break, max) {
		// Link Active
		color: $light;
		background-color: $primary;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	color: #666666;
	font-family: $displayFont;
	transition-duration: 366ms;
	transition-timing-function: ease-in-out;
	transition-property: box-shadow;

	@include breakpoint($break, max) {
		overflow-y: auto;
		transition-property: transform;
	}

	@include breakpoint(giant) {
		padding: rem($baseLineHeight / 2) 0;
	}

	@include breakpoint(huge) {
		padding: rem($baseLineHeight / 1.5) 0;
	}

	@include breakpoint(full) {
		padding: rem($baseLineHeight * 1.075) 0;
	}

	&.isReduced {
		@include breakpoint($break) {
			box-shadow: 0 0 em($baseGap, $baseFontSize) rgba(black, 0.125);
		}
	}

	#navigationContainer {
		@include breakpoint($break, max) {
			margin: 0 rem(-$baseGap);
			padding: rem($baseLineHeight / 2) 0;
		}

		@include breakpoint(giant) {
			@include breakpoint(huge, max) {
				font-size: 0.9em;
			}
		}

		@include breakpoint(huge) {
			margin-left: 3%;
		}

		@include breakpoint(full) {
			margin-left: 4.5%;
		}
	}

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;
							transition: transform 366ms ease-in-out;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}

	.branding {
		padding: 0;
		margin-left: 0;

		@include breakpoint(full, max) {
			display: none;
		}
	}

	.quickContact {
		font-size: rem(15px);
		display: flex;
		text-transform: none;
		justify-content: space-between;
		margin-bottom: rem($baseLineHeight / 2);

		@include breakpoint($break, max) {
			background-color: $secondary;
			margin: 0 rem(-$baseGap);
			color: $light;
			padding: rem($baseLineHeight) rem($baseGap);
			display: block;
		}

		@include breakpoint(giant) {
			max-width: 89.15%;
			margin-left: auto;
			border-bottom: 1px solid #ededed;
			padding-bottom: rem(18px);
		}

		@include breakpoint(huge) {
			margin-bottom: rem($baseLineHeight / 1.5);
			max-width: 82.15%;
		}

		@include breakpoint(full) {
			max-width: 90.15%;
			font-size: rem(17px);
		}

		li {
			&:not(.facebook) {
				a {
					@extend .linkHover;

					@include breakpoint($break) {
						color: $primary;
					}
				}
			}

			&.facebook {
				@include breakpoint($break, max) {
					display: none;
				}

				a {
					@include hideText;
					display: block;
					width: rem(22px);
					height: rem(22px);
					background: image-url("layout/social-facebook.svg") no-repeat center center / contain;
					transition: opacity 366ms ease-in-out;

					&:hover, &:active, &:focus {
						opacity: 0.75;
					}
				}
			}
		}

		a {
			color: inherit;
		}

		span.desc {
			@include breakpoint($break) {
				@include breakpoint(giant, max) {
					display: none;
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		nav {
			ul {
				&.navi {
					> li {
						&, &:not(.navBack), &.hasSub {
							&, &:hover {
								> a, > span, > span.active {
									padding-left: 0;
									padding-right: 0;
									background-color: transparent;
									justify-content: flex-end;
									// text-transform: uppercase;
									font-size: rem(19px);
								}
							}

							&.active {
								> a, > span {
									background-color: transparent;
								}
							}

							&:hover, &.active, &.hasSub.active {
								> a, > span, > span.active {
									color: $primary;
									background-color: transparent;

									&:after {
										transform: none !important;
										opacity: 0.5 !important;
									}
								}
							}

							&, &.hasSub {
								> a, > span {
									color: $dark;
									padding-top: 0;
									padding-bottom: 0;
									flex-direction: row-reverse;
								}
							}
						}

						&.hasSub {
							> a, > span {
								justify-content: flex-end;

								&:after {
									max-width: calc(100% - #{1.5em});
									border-color: $light;
								}
							}
						}

						> a, > span {
							padding: 0;
	
							&.active, &:hover {
								background-color: transparent !important;
							}

							&:after {
								border-color: $light;
								margin-top: 0.25em;
							}
						}
					}

					li {
						&.startseite, &.widerrufsbelehrung, &.kontakt, &.anfahrt, &.datenschutzerklaerung, &.impressum, &.facebook {
							display: none;
						}

						&.hasSub {
							> a, > span {
								flex-wrap: wrap;

								&:after {
									width: 100%;
								}
							}

							.sub {
								background-color: transparent;
								border-top: rem(18px) solid transparent;
								transition-property: opacity, visibility;
								left: auto;
								right: 0;

								.sub {
									border-top: 0;
									border-left: 0.25em solid transparent;
								}

								li {
									box-shadow: 0 0 em($baseGap / 4, $baseLineHeight) rgba(black, 0.075);
									position: relative;

									&:before {
										content: "";
										background-color: $light;
										z-index: -2;
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										bottom: 0;
									}

									&:not(:last-child) {
										margin-bottom: 0.125em;
									}

									&.hasSub {
										&:hover {
											> a, > span {
												&:before {
													transform: rotate(90deg);
												}

												&:after {
													transform: none;
													opacity: 1;
												}
											}
										}

										> a, > span {
											display: flex;
											flex-direction: row-reverse;
											flex-wrap: nowrap;

											&:before {
												transform: rotate(-90deg);
												transition: transform 366ms ease-in-out;
											}
										}
									}

									&.active {
										> a, > span {
											background-color: transparent;
											color: $light;

											&:after {
												opacity: 1;
												transform: none;
											}
										}
									}
								}

								a, span {
									position: relative;
									transition-property: color;
									background-color: transparent;
									font-size: 1em;

									&:hover, &:active, &:focus, &.active {
										background-color: transparent;
										color: $light;

										&:after {
											opacity: 1;
										}
									}

									&:after {
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										bottom: 0;
									}

									&:after {
										z-index: -1;
										border: none;
										background-color: $primary;
									}
								}
							}
						}

						&.navBack {
							display: none;
						}
					}
				}
			}
		}
	}
}

#navigation {
	nav ul.navi li.hasSub .sub {
		@media(min-width: em(1552px, $bpContext)) {
			left: 0;
			right: auto;
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

	.branding {
		background-color: transparent;
		color: inherit;
		margin: 0;
		padding: 0;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////

.naviAdd {
	display: flex;
	justify-content: flex-end;
	padding: rem($baseLineHeight) 0;
	font-family: $displayFont;

	@include breakpoint($break, max) {
		display: none;
	}

	@include breakpoint(giant) {
		padding: rem($baseLineHeight * 1.25) 0;
		margin-right: rem($baseGap / 2);
	}

	@include breakpoint(huge) {
		margin-right: rem($baseGap * 1.5);
	}

	@include breakpoint(full) {
		margin-right: rem($baseGap * 2.5);
	}

	li {
		&:not(:last-child) {
			margin-right: rem($baseGap);

			@include breakpoint(large) {
				margin-right: rem($baseGap * 1.25);
			}

			@include breakpoint(giant) {
				margin-right: rem($baseGap * 1.75);
			}
		}
	}

	a {
		@extend .linkHover;
		color: $dark;
	}
}