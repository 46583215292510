/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 26px;

$h1Size: 34px;

$h2Size: 28px;

$h3Size: 26px;

$h4Size: 24px;

$h5Size: 22px;

$h6Size: 20px;

$mainFont: "Open Sans", sans-serif;

$displayFont: "Barlow", sans-serif;

$iconFont: "icomoon";

// Project colors

$dark: #2d2d2d;
$light: #fff;
$primary: #8c0842;
$secondary: mix($primary, $light, 87.5%);
$medium: #F2F2F0;
$border: #bbb;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidth: 1520px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "\ea1f", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	play: "\ea29", 
	envelope: "\e9b0", 
	fax: "\e9b9", 
	phone: "\ea81", 
	check-round: "\eaa7", 
	bookmark: "\e936", 
	search: "\eabf", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		center: (justify-content:center),
		flex: (display:flex),
		middle: (align-items:center),
		stretch: (align-items:stretch),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (self, 6, self),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (6, 10),
		flex: (display:flex),
		stretch: (align-items:stretch),
	),
	medium: (
		width: em(760px, $bpContext), 
		between: (justify-content:space-between),
		cols: (4, 6, 12),
		flex: (display:flex),
		stretch: (align-items:stretch),
		column: (flex-direction:column),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (3, 4),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (2, 3, 10, auto),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (3, 5, 10),
		flex: (display:flex),
		middle: (align-items:center),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (self, 9, 10, self, auto),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

