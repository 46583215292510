// PAGEWRAP
//////////////////////////////
#pageWrap {
    padding-top: rem($barHeight);
    @include breakpoint(giant) {
        padding-top: rem(51.95px);
    }
    @include breakpoint(huge) {
        padding-top: rem(62.98px);
    }
    @include breakpoint(full) {
        padding-top: rem(126.38px);
    }
}

// SITEWIDE BRANDING
//////////////////////////////
.branding {
    display: table;
    margin: 0 auto;
    padding: rem($baseLineHeight / 1.5) rem($baseGap);
    // background-color: $light;
    &:hover,
    &:active,
    &:focus {
        img {
            opacity: 0.75;
        }
    }
    img {
        display: block;
        transition: opacity 366ms ease-in-out;
    }
}

// HEADER
//////////////////////////////
#header {
    position: relative;
    color: $light;
    text-align: center;
    // lets define how our header-padding-top should scale fluidly
    $headerPaddingTop: ( 320px: $baseLineHeight * 2, map-get($breakpointsPx, 'cut'): $baseLineHeight * 5.5);
    // lets make our header-padding-top scale fluidly
    @include poly-fluid-sizing('padding-top', $headerPaddingTop);
    // lets define how our header-padding-bottom should scale fluidly
    $headerPaddingBottom: ( 320px: (104.55 * $baseLineHeight * 2) / 100, map-get($breakpointsPx, 'cut'): (104.55 * $baseLineHeight * 5.5) / 100);
    // lets make our header-padding-bottom scale fluidly
    @include poly-fluid-sizing('padding-bottom', $headerPaddingBottom);
    body:not(.index) & {
        padding: rem($baseLineHeight * 1.5) 0;
    }
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &:before {
        z-index: -1;
        /* background-color: rgba(black, 0.25);
		
		body:not(.index) & {
			background-color: rgba(black, 0.25);
		} */
    }
    &:after {
        background-size: cover;
        background-position: inerhert;
        z-index: -2;
        @include responsiveImage("layout/bgHeader.jpg", (tiny, medium, giant), false);
    }
    .branding {
        @extend .defaultFullMarginBottom;
        @include breakpoint(full) {
            display: none;
        }
    }
    .headline {
        font-family: $displayFont;
        font-weight: 600;
        @include responsive-font-size(34px);
        text-transform: uppercase;
        letter-spacing: 0.05em;
        line-height: 1.3;
        @extend .defaultFullMarginBottom;
        color: $dark;
        text-shadow: 0.1em 0.1em 1.2em white;
        @include breakpoint(tiny) {
            // font-size: rem(15.5px);
            padding-top: 2rem !important;
        }
        @include breakpoint(small) {
            padding-top: 2rem !important;
        }
        @include breakpoint(medium) {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        @include breakpoint(giant) {
            max-width: 100%;
        }
        @include breakpoint(huge) {
            max-width: 100%;
            margin-bottom: rem($baseLineHeight * 1.125);
        }
        h4 {
            color: $primary;
        }
        span {
            display: block;
            text-transform: none;
            letter-spacing: 0;
            font-weight: 600;
            font-size: em(32px, 34px);
            @include breakpoint(huge) {
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
            body.index & {
                &:before {
                    content: "";
                    display: table;
                    border-bottom: 1px solid $dark;
                    width: em(89px, 32px);
                    margin: rem($baseLineHeight / 2) auto rem($baseLineHeight / 2 / 1.5);
                    @include breakpoint(large) {
                        margin: rem($baseLineHeight / 1.5) auto rem($baseLineHeight / 1.5 / 1.5);
                    }
                    @include breakpoint(huge) {
                        margin: rem($baseLineHeight) auto rem($baseLineHeight / 1.5);
                    }
                }
            }
        }
    }
    .buttons {
        >.col:not(:last-child) {
            @include breakpoint(tiny, max) {
                margin-bottom: rem($baseGap / 2);
            }
        }
        .btn {
            @include breakpoint(tiny, max) {
                width: 100%;
            }
            body:not(.index) & {
                font-size: 0.9em;
                padding: 0.85em 1.1em;
            }
            [class^='icon'] {
                position: relative;
                top: -0.075em;
                margin-right: 0;
            }
            &.phone {
                font-size: 1.1rem;
                font-weight: 600;
            }
        }
    }
}

// MAIN CONTENT
//////////////////////////////
main {
    display: block;
    overflow: hidden;
    // lets define how our main-padding-top should scale fluidly
    $mainPaddingTop: ( 320px: $baseLineHeight * 2, map-get($breakpointsPx, 'cut'): 90px);
    // lets make our main-padding-top scale fluidly
    @include poly-fluid-sizing('padding-top', $mainPaddingTop);
    // lets define how our main-padding-bottom should scale fluidly
    $mainPaddingBottom: ( 320px: $baseLineHeight * 2, map-get($breakpointsPx, 'cut'): 90px + $baseLineHeight / 2);
    // lets make our main-padding-bottom scale fluidly
    @include poly-fluid-sizing('padding-bottom', $mainPaddingBottom);
    .row.hasDeco {
        &1,
        &2,
        &3 {
            &:before {
                content: "";
                position: absolute;
                border-width: 0;
                border-style: solid;
                border-color: #d8b98e;
                z-index: -1;
                @include breakpoint($break, max) {
                    opacity: 0.25;
                    height: 200% !important;
                }
            }
        }
        &1:before {
            width: em(465px, $baseFontSize);
            height: em(805px, $baseFontSize);
            transform: rotate(30deg) translate3d(-60%, -1.5%, 0);
            top: 0;
            border-top-width: em(10px, $baseFontSize);
            border-right-width: em(10px, $baseFontSize);
            left: calc(50% - 50vw);
            display: none;
        }
        &2:before {
            width: em(554px, $baseFontSize);
            height: em(876px, $baseFontSize);
            transform: rotate(-30deg) translate3d(36%, -31.5%, 0);
            top: 0;
            right: calc(50% - 50vw);
            border-left-width: em(10px, $baseFontSize);
            display: none;
        }
        &3:before {
            width: em(483px, $baseFontSize);
            height: em(876px, $baseFontSize);
            transform: rotate(15deg) translate3d(-12%, -69.5%, 0);
            top: 0;
            left: calc(50% - 50vw);
            border-right-width: em(10px, $baseFontSize);
            border-bottom-width: em(10px, $baseFontSize);
            display: none;
        }
        p {
            margin-bottom: 1.44444rem !important;
        }
    }
    .teaser {
        color: $light;
        @extend %teaserMargin;
        position: relative;
        z-index: 1;
        &.topicsTeaser {
            // background-color: $primary;
            a {
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                font-size: em(18.72px, $baseFontSize);
                // lets define how our main .topicsTeaser a-padding (y-axis) should scale fluidly
                $topicsTeaserAnchorPaddingTop: ( 320px: $baseLineHeight, map-get($breakpointsPx, 'cut'): 165px);
                // lets make our main .topicsTeaser a-padding (y-axis) scale fluidly
                @include poly-fluid-sizing('padding-top', $topicsTeaserAnchorPaddingTop);
                // lets define how our main .topicsTeaser a-padding (y-axis) should scale fluidly
                $topicsTeaserAnchorPaddingBottom: ( 320px: (56.97 * $baseLineHeight) / 100, map-get($breakpointsPx, 'cut'): (56.97 * 165px) / 100);
                @include poly-fluid-sizing('padding-bottom', $topicsTeaserAnchorPaddingBottom);
                &:hover,
                &:active,
                &:focus {
                    &:before {
                        opacity: 0.1;
                    }
                    &:after {
                        transform: scale(1.125);
                    }
                    >span {
                        background-color: $primary;
                    }
                }
                &:before,
                &:after,
                >span {
                    transition-duration: 366ms;
                    transition-timing-function: ease-in-out;
                }
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                &:before {
                    z-index: -1;
                    background-color: rgba(black, 0.2);
                    transition-property: opacity;
                }
                &:after {
                    background-size: cover;
                    background-position: center center;
                    transition-property: transform;
                    z-index: -2;
                }
                &[href*="bewertung"].lazyLoaded:after {
                    @include responsiveImage("layout/bgTeaserBewertung.jpg", (tiny, medium, giant), false);
                }
                &[href*="vermietung"].lazyLoaded:after {
                    @include responsiveImage("layout/bgTeaserVermietung.jpg", (tiny, medium, giant), false);
                }
                &[href*="verkauf"].lazyLoaded:after {
                    @include responsiveImage("layout/bgTeaserVerkauf.jpg", (tiny, medium, giant), false);
                }
                &[href*="auswahl"].lazyLoaded:after {
                    @include responsiveImage("layout/bgTeaserImmobilien.jpg", (tiny, medium, giant), false);
                }
                >span {
                    font-style: italic;
                    background-color: $dark;
                    color: $light;
                    padding: rem($baseLineHeight / 1.5) rem($baseGap);
                    transition-property: background-color;
                    @include breakpoint(giant) {
                        padding: rem($baseLineHeight / 1.25) rem($baseGap * 1.5);
                    }
                    [class^='icon'] {
                        font-size: em(21px, 18.72px);
                    }
                }
            }
            ul {
                margin-bottom: 0 !important;
                padding: rem($baseLineHeight * 1.5) 0;
                @include breakpoint(small) {
                    padding: rem($baseLineHeight * 1.75) 0;
                }
                @include breakpoint(full) {
                    display: flex;
                    justify-content: space-between;
                }
                li {
                    padding-left: 0;
                    margin-bottom: 0;
                    @include breakpoint(full, max) {
                        text-align: center;
                    }
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &.videoTeaser {
            text-align: center;
            &.lazyLoaded button:not(#pause-button):after {
                @include responsiveImage("layout/bgVideo.jpg", (tiny, medium, giant), false);
            }
            button:not(#pause-button) {
                background-color: transparent;
                color: inherit;
                display: block;
                width: 100%;
                position: relative;
                font-family: $mainFont;
                cursor: pointer;
                @include responsive-font-size(200px);
                overflow: hidden;
                // lets define how our main .videoTeaser-padding (y-axis) should scale fluidly
                $videoTeaserPaddingY: ( 320px: $baseLineHeight * 1.5, map-get($breakpointsPx, 'cut'): 210px);
                // lets make our main .videoTeaser-padding (y-axis) scale fluidly
                @include poly-fluid-sizing('padding-top', $videoTeaserPaddingY);
                @include poly-fluid-sizing('padding-bottom', $videoTeaserPaddingY);
                &:hover,
                &:active,
                &:focus {
                    &:before {
                        opacity: 0.5;
                    }
                    &:after {
                        transform: scale(1.125);
                    }
                }
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transition-duration: 366ms;
                    transition-timing-function: ease-in-out;
                }
                &:before {
                    z-index: -1;
                    background-color: rgba(black, 0.5);
                    transition-property: opacity;
                }
                &:after {
                    background-size: cover;
                    background-position: center center;
                    transition-property: transform;
                    z-index: -2;
                }
                >span {
                    display: block;
                    line-height: 1.3;
                    >span {
                        display: block;
                        &:not([class^='icon']) {
                            font-size: 0.5em;
                            font-weight: 600;
                            margin-top: -0.5em;
                        }
                    }
                }
            }
        }
        .boxes>.col {
            padding: 0.5rem;
        }
    }
    .mitarbeiter {
        padding: rem($baseLineHeight / 2) rem($baseGap);
        border: 1px solid rgba($primary, 0.5);
        margin-bottom: rem($baseLineHeight);
        width: 100%;
        @include breakpoint(giant) {
            margin-bottom: rem($baseLineHeight * 1.5);
            padding: rem($baseLineHeight) rem($baseGap);
        }
        @include breakpoint(huge) {
            margin-bottom: rem($baseLineHeight * 2);
        }
        h2+a {
            margin-bottom: 0;
        }
        .btn {
            margin-top: rem($baseLineHeight / 2);
            &:before {
                display: none;
            }
        }
    }
    p,
    ol,
    ul,
    dl,
    table,
    label,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        a:not(.btn) {
            text-decoration: underline;
            color: $primary;
            &:hover,
            &:active,
            &:focus {
                color: $primary;
                text-decoration: none;
            }
        }
    }
    p,
    ol,
    ul,
    dl,
    table {
        @extend .marginBottom;
    }
    ul:not(.unstyledList),
    dl:not(.unstyledList),
    ol:not(.unstyledList) {
        @extend .styledList;
    }
    picture>img {
        box-shadow: none;
    }
    picture[class*='marginBottom']>img[class*='marginBottom'] {
        margin-bottom: 0;
    }
    .d-flex {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        @include breakpoint(large) {
            flex-direction: row;
            justify-content: space-between;
        }
        .btn {
            margin: 0.5rem 0;
        }
    }
}

#privacyPolicy {
    a {
        display: inline-block;
        word-break: break-all;
    }
}

// FOOTER
//////////////////////////////
#footer {
    iframe,
    .googleMaps {
        // lets define how our #footer iframe (aka map) height should scale fluidly
        $footerMapHeight: ( 320px: 250px, map-get($breakpointsPx, 'cut'): 449px);
        // lets make our #footer iframe (aka map) height scale fluidly
        @include poly-fluid-sizing('height', $footerMapHeight);
    }
    .googleMaps {
        padding-bottom: 0;
    }
    .oz-box {
        padding: 0.5rem;
        background-color: $primary;
        color: $light;
    }
}

.quickContact {
    font-size: rem(15px);
    display: flex;
    text-transform: none;
    justify-content: space-between;
    @include breakpoint($break, max) {
        padding: rem($baseGap);
        display: none;
    }
    @include breakpoint(giant) {
        max-width: 89.15%;
        margin-left: $baseGap*2;
        border-top: 1px solid #ededed;
        padding: rem(18px);
    }
    @include breakpoint(huge) {
        max-width: 82.15%;
    }
    @include breakpoint(full) {
        max-width: 90.15%;
        font-size: rem(17px);
    }
    li {
        &:not(.facebook) {
            a {
                @extend .linkHover;
                @include breakpoint($break) {
                    color: $primary;
                }
            }
        }
        &.facebook {
            @include breakpoint($break, max) {
                display: none;
            }
            a {
                @include hideText;
                display: block;
                width: rem(22px);
                height: rem(22px);
                background: image-url("layout/social-facebook.svg") no-repeat center center / contain;
                transition: opacity 366ms ease-in-out;
                &:hover,
                &:active,
                &:focus {
                    opacity: 0.75;
                }
            }
        }
    }
    a {
        color: inherit;
    }
    span.desc {
        @include breakpoint($break) {
            @include breakpoint(giant,
            max) {
                display: none;
            }
        }
    }
}

main {
    ul {
        &.dividedList:not(.unstyledList) {
            column-count: 1;
        }
    }
    .red {
        color: $primary;
    }
}

.block {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.sub{
    z-index: 9;
    
}