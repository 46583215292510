/**
* OpenImmo Module
* @author Kerem Agar
* @edit Jasmin Reiff
* @version 1.8.1
* @date March 03, 2020
*/

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/

// NOTIFICATION
////////////////////////////////////

#immo_notification {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	z-index: 99999;
	text-shadow: none;

	.alert {
		padding: 1em 2em;
		background-color: black;
		color: white;
		border-top: rem(1px) solid white;
		min-height: $barHeight;

		&:first-of-type {
			border-top: 0;
		}
	}

	.alert-blank {
		background-color: $dark;
		color: $light;
	}

	.alert-red {
		color: white;
		background-color: #DA4453;
		border-top-color: #2b2020;
	}

	.alert-green {
		color: white;
		background-color: #37BC9B;
		border-top-color: #e3e3e3;
	}

	.alert-orange {
		color: white;
		background-color: #E9573F;
		border-top-color: #e3e3e3;
	}

	.buttons {
		margin-bottom: rem(20px);
		display: block;
	}
}


// Object box in the overview
////////////////////////////////////

.immo_objectBox {
	position: relative;
	
	.immo_objectFlex {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	
		.immo_cover {
			position: relative;
			height: 0;
            padding-top: 66.75%;
            position: relative;
            margin: 0;

			.immo_coverImage {
				display: block;
				margin: 0;
				border-radius: 0;
				overflow: hidden;

				position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
			}

			img {
				@include object-fit(cover, center center);
				height: 100%;
			}

			.immo_objectBadges {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 100;
				display: block;
				width: auto;

				mark {
					background: $dark;
					text-transform: uppercase;
					font-size: rem(11px);
					line-height: rem(30px);
					letter-spacing: 2px;
					height: rem(40px);
					font-weight: bold;
					color: $light;
					padding: rem(5px) rem(10px);
					display: inline-block;

					&:nth-child(odd) {
						background: darken($dark,5%);
					}
				}
			}

			.immo_bookmark {
				@extend .btn;
				cursor: pointer;
				position: absolute;
                bottom: 0;
                right: 0;
				width: 3.4em;
				height: 3.4em;
				line-height: 3.4em !important;
				padding: 0 !important;
				z-index: 100;
				display: flex !important;
				align-items: center;
				justify-content: center;

				&:before, &:after {
					content: none !important;
				}

				&:hover, &:focus {
					svg {
						transform: scale(1.1);
					}
				}

				svg {
					display: block;
                    transition: all 300ms ease-in-out;

					path {
						@extend %animatedTransform;
						stroke-width: 1.5px;
						stroke: $light;
						fill: none;
					}
				}

				&.wait {
					pointer-events: none !important;
					opacity: .7 !important;
				}

				&.active {
					svg {
						path {
							fill: $light;
						}
					}
				}
			}
		}

		.immo_details {
			background: darken($light, 3%);
			color: $dark;
			padding: rem($baseGap);
			width: 100%;
			padding: rem($baseLineHeight) rem($baseGap) rem($baseLineHeight+50px) rem($baseGap);
			display: flex;
			flex-flow: column nowrap;
			flex-grow: 1;
			position: relative;

			.immo_tags, .immo_objectHeadline, .immo_table {
				margin-bottom: rem($baseGap);
			}

			.immo_objectHeadline {
				color: $dark;
				font-size: rem(16px);
				line-height: rem(25px);
				display: inline-block;
				width: 100%;
				margin: 0;
				padding: 0;
			}

			.immo_table {
				display: table!important;
				border-collapse: collapse!important;
				width: 100%!important;
				max-width: 100%!important;
				text-align: left!important;
				font-size: rem($baseFontSize)!important;
				margin-top: 0!important;
				margin-left: 0!important;
				margin-right: 0!important;
				padding: 0!important;

				tbody {
					width: 100%!important;
					overflow-x: scroll!important;
					display: table-row-group!important;
			
					tr {
						border-right: 0!important;
						border-left: 0!important;
						border-bottom: 0!important;
						display: table-row!important;
						&:not(:first-child) {
							border-top: $baseBorder!important;
						}

						td {
							line-height: 1.4em;
							font-size: rem(14px);
							padding: 0.6em 1em!important;
							display: table-cell!important;
							vertical-align: top!important;
							min-width: 0!important;
							max-width: none!important;
							
							&:first-child {
								padding-left: 0!important;
							}

							&:not(:last-child) {
								border-right: $baseBorder!important;
							}
	
							@include breakpoint(medium, max) {
								font-size: rem(12px);
							}

							span {
								display: inline;
								margin: 0;
								font-weight: inherit;
								color: inherit;
							}
							small {
								@include breakpoint(small, max) {
									font-size: inherit;
								}
							}		
						}
					}
				}
			}

			.immo_btnObject {
				position: absolute;
				bottom: 0;
				left: 0;
				font-size: rem(14px);
				padding: 1.1em 1.8em 1em;
				margin: 0;
				width: 100%;
				display: block;
			}
		} // .immo_details
	} // .immo_objectFlex
} // .immo_objectBox

// DETAIL VIEW
////////////////////////////////////

#immo_detail {
	.immo_cover {
		@include breakpoint(large,max) {
			margin-top: rem($baseGap);
			order: 1;
		}

		@include breakpoint(large) {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.swiper-container {
			margin-left: auto;
			margin-right: auto;
			position: relative;
			overflow: hidden;
			list-style: none;
			padding: 0;
			z-index: 1;
			width: 100%;

			.swiper-wrapper {
				position: relative;
				width: 100%;
				height: 100%;
				z-index: 1;
				display: flex;
				transition-property: transform;
				box-sizing: content-box;
	
				.swiper-slide {
					box-sizing: border-box;
					flex-shrink: 0;
					flex-grow: 0;
					width: auto;
					transition: all .2s;
					position: relative;
				}
			}

			&.swiper-container-autoheight {
				height: auto;

				.swiper-wrapper {
					align-items: flex-start;
					transition-property: transform, height;

					.swiper-slide {
						height: auto;
					}
				}
			}
		}

		#immo_coverSlider {
			height: rem(230px);
			background: lighten($medium, 20%);
			border: 0.5rem solid lighten($medium, 20%);
			// width: calc(100& - 3rem);
			// box-sizing: content-box;

			@include breakpoint(tiny) {
				height: rem(330px);
			}

			@include breakpoint(small) {
				height: rem(440px);
			}

			@include breakpoint(medium) {
				height: rem(550px);
				border: 1.5rem solid lighten($medium, 20%);
			}

			@include breakpoint(large) {
				height: rem(420px);
			}

			@include breakpoint(giant) {
				height: rem(500px);
			}

			@include breakpoint(huge) {
				height: rem(575px);
			}

			@include breakpoint(full) {
				height: rem(625px);
			}

			.swiper-pagination-fraction, .swiper-title {
				position: absolute;
				top: 2rem;
				right: 1rem;
				bottom: auto;
				left: auto;
				width: auto;
				background: $light;
				color: darken($medium, 35%);
				line-height: 1.2em;
				font-size: rem($baseFontSize - 5);
				z-index: 1;
			}

			.swiper-pagination-fraction {
				@include breakpoint(tiny) {
					font-size: rem($baseFontSize);
				}
				@include breakpoint(medium,max) {
					top: 0;
					left: 0;
					right: auto;
					bottom: auto;
				}
		
				span {
					display: inline-block;
					padding: 0.5em 0.2em;
					font-weight: lighter;

					&.swiper-pagination-current {
						padding-left: 0.8em;
						font-weight: bold;
						font-size: 1.3em;
		
						@include breakpoint(small) {
							font-size: 1.5em;
						}
					}

					&.swiper-pagination-total {
						font-weight: 400;
						padding-right: 0.8em;
					}
				}
			}

			.swiper-title {
				@include breakpoint(medium,max) {
					// bottom: 0;
					// left: 0;
					background: none;
					text-align: center;
					right: auto;
					top: auto;
					position: relative;
					width: 100%;
					max-width: 100%;	
					order: 1;			
					margin-top: 0.5rem;	
				}
				@include breakpoint(medium) {
					top: 5.5rem;
					transform-origin: right center;
					transition: 0.5s 1s cubic-bezier(0.175, 0.885, 0.320, 1.275);
					max-width: 35%;
					text-align: center;
				}

				font-size: rem(14px);
				padding: 0.5em 0.8em;
			}

			.swiper-slide {
				&:not(.swiper-slide-active) {
					.swiper-title {
						@include breakpoint(medium) {
							opacity: 0;
							transform: rotateY(-100deg);
						}
					}
				}

				@include breakpoint(medium, max) {
					display: flex;
					flex-flow: column nowrap;
					justify-content: flex-start;
					align-items: flex-start;
					height: 100%;

					a[data-rel] {
						flex-grow: 1;
						height: 1px;
						display: block;
						width: 100%;
					}
				}

				img {
					@include object-fit(contain, center center);
					width: 100%;
					height: 100%;	
				}
			}
		}
	
		#immo_coverSliderThumb {
			height: 3rem;
			margin-top: 10px;
	
			@include breakpoint(tiny) {
				height: 4rem;
			}
	
			@include breakpoint(giant) {
				height: 6rem;
			}
	
	
			.swiper-slide {
				opacity: 0.6;
				transition: all .2s;
				height: 100%;
				cursor: pointer;
	
				img {
					width: auto;
					max-width: none;
					height: 100%;
				}
		
				&.swiper-slide-thumb-active,&:hover, &:focus {
					opacity: 1;
				}
			}
	
			.swiper-button-prev, .swiper-button-next {
				position: absolute;
				z-index: 10;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $dark;
				background: $light;
				transition: all .4s;
				top: 50%;
				transform: translateY(-50%);

				&.swiper-button-disabled {
					opacity: 0;
					cursor: auto;
					pointer-events: none;
				}

				&:after {
					font-family: $iconFont;
					font-size: rem(22px);
					content: map-get($iconMap, right);
				}

				@include breakpoint(medium,max) {
					width: rem(30px);
					height: rem(30px);
				}

				@include breakpoint(large) {
					width: rem(50px);
					height: rem(50px);	
				}
			}
	
			.swiper-button-prev {
				left: rem(-5px);
				right: auto;

				&:after {
					transform: rotateY(180deg);
				}

				&:hover, &:focus {
					left: 0;
				}
			}
	
			.swiper-button-next {
				right: rem(-5px);
				left: auto;

				&:hover, &:focus {
					right: 0;
				}
			}
	
			.swiper-button-lock {
				display: none;
			}
		} // #immo_coverSliderThumb 

		.immo_imageBtn {
			margin-top: rem(10px);

			.btn {
				margin: 0 rem(10px) rem(10px) 0;
				font-size: rem(12px);
				line-height: rem(18px);

				@include breakpoint(small, max) {
					width: 100%;
					margin: 0 0 rem(10px) 0;
				}

				&:last-of-type {
					margin: 0;
				}
			}
		} // .immo_imageBtn
	} // .immo_cover (Swiper)

	h1, h2, h3 {
		span {
			padding-top: rem(20px);
			display: block;
			font-size: 75%;
		}
	}

	address {
		font-style: normal;
	}

	> .row {
		@extend .space;

		+ .row {
			&:after {
				content: '';
				top: 0;
				position: absolute;
				border-top: $baseBorder;
				left: rem($baseGap);
				right: rem($baseGap);
				opacity: 0.45;
			}
		}

		&.noBottom {
			padding-bottom: 0 !important;

			+ .row {
				&:after {
					content: none;
				}
			}
		}
	}


	.immo_fastInfos {
		@include breakpoint(large) {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.immo_objectHeadline {
			color: $dark;
			font-size: rem(16px);
			line-height: rem(25px);
			display: inline-block;
			width: 100%;
			margin: 0;
			padding: 0;
		}

		dl {
			dt {
				font-weight: bold;
			}

			dt, dd {
				background: lighten($medium,20%);
			}
		}

		.immo_btnfixSidebarBookmark {
			margin-bottom: 0;
		}
	}

	.immo_infoList {
		columns: 3 rem(250px);
		column-gap: rem($baseGap / 2);

		dl {
			break-inside: avoid;
			margin-bottom: rem($baseGap / 2) !important;
			display: inline-block;
			width:100%;

			span {
				display: block;
			}
		}
	}

	.immo_oguloNotice {
		font-size: rem(12px);
		margin: 0 !important;
		padding: 0 !important;
	}
		
	.immo_energyBar {
		background: #028141;
		background: linear-gradient(to right, #028141 0%, #88bf3f 25%, #f9f23f 50%, #db7532 75%, #bd0e23 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#028141', endColorstr='#bd0e23', GradientType=1);
		width: 100%;
		height: rem(16px);
		// border-radius: rem(10px);
		margin-bottom: rem(50px);
		position: relative;
		// z-index: 10000;
		
		&:before {
			content: "0";
			position: absolute;
			top: 100%;
			left: 0;
		}
	
		&:after {
			content: "250";
			position: absolute;
			top: 100%;
			right: 0;
		}
	
		&:not(:hover) {
			.knob {
				&:before,
				&:after {
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	
		.knob {
			width: rem(5px);
			height: rem(5px);
			background: $light;
			border: rem(10px) solid $dark;
			// border-radius: 50%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			box-sizing: content-box;
	
			&:before, &:after {
				@extend %animatedTransform;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}

			&:after {
				content: attr(data-val);
				background: $dark;
				color: $light;
				padding: rem(10px);
				display: block;
				top: rem(-69px);
				white-space: nowrap;
			}

			&:before {
				content: "";
				border-style: solid;
				border-width: rem(10px) rem(8px) 0 rem(8px);
				border-color: $dark transparent transparent transparent;
				top: -1.5rem;
				width: 0;
				height: 0;
			}
		}
	}

	.immo_energyNotice {
		font-size: .8rem;
		color: lighten($dark,20);
	}
} // #immo_details


// BOOKMARK + REQUEST
////////////////////////////////////

.immo_bookmark_status {
	* {
		font-size: rem(14px);
		line-height: rem(18px);
	}
}

.immo_bookmarkNotice {
	font-size: rem(12px);
	line-height: rem(18px);
	margin: 0 !important;
	padding: 0 !important;
}

.immo_captcha {
	margin: rem(10px) 0 rem(35px) 0;
}

.immo_request {
	fieldset {
		border: none;
		padding-top: 1.5625rem!important;
		padding-bottom: 1.5625rem!important;
		margin: 0!important;
	}

	label, input, textarea, select, button, [type="submit"] {
		min-height: 0 !important;
		display: block !important;
	}

	[type="checkbox"] {
		float:left!important;
		display: inline-block!important;
		margin: 0.2rem .9375rem 0.5rem 0!important;
		padding: 0!important;
		width: .9375rem!important;
		height: .9375rem!important;

		&#Datenschutz {
			float: none!important;
		}
	}

	label {
		clear: unset !important;
		cursor: pointer;
	}

	.immo_btnRequest {
		margin: 0 auto !important;
		width: auto !important;
	}
}

.immo_filter {
	&, .col {
		@include breakpoint(tiny) {
			padding: 0 rem($baseGap);
		}
	}

	.col {
		@include breakpoint(tiny) {
			width: auto;
			flex-basis: auto;
			display: inline-block;
			flex-grow: 0;
		}

		@include breakpoint(tiny, max) {
			margin-top: 1rem;
		}

		@include breakpoint(medium) {
			display: flex;
		}

		&:first-of-type {
			@include breakpoint(tiny, max) {
				margin-top: 0;
			}
		}
	}
	
	label {
		margin-bottom: 0.3rem;

		@include breakpoint(tiny) {
			margin-right: 1.3em;
			margin-bottom: 0;
			display: flex;
			align-items: center;
		}
	}

	.immo_select {
		position: relative;
		display: flex;
		line-height: 3;
		overflow: hidden;
		font-size: 17px!important;

		select {
			@extend %animatedTransform;
			background: $light;
			border: $baseBorder;
			color: $dark;
			display: block;
			font-family: $mainFont;
			font-size: rem($baseFontSize);
			line-height: rem($baseLineHeight);
			padding: rem(10px) rem(15px);
			position: relative;
			resize: none;
			width: 100%;
			border-radius: 0;
	
			&:hover {
				border-color: $secondary;
			}
			&:focus {
				border-color: $primary;
				outline: none;
			}			
		}
		// select {
		// 	appearance: none;
		// 	outline: 0;
		// 	box-shadow: none;
		// 	border: 0 !important;
		// 	background-image: none;
		// 	font-size: rem(17px)!important;
		// 	background: $medium;
		// 	padding: .5rem 3rem .5rem .5rem;
		// 	width: 100%;
		// 	color: $dark;
		// }

		// select::-ms-expand {
		// 	display: none;
		// }

		// &:after {
		// 	color: $dark;
		// 	font-size: 22px;
		// 	display: flex;
		// 	align-items: center;
		// 	position: absolute;
		// 	top: 50%;
		// 	right: 20px;
		// 	transform: translateY(-50%);
		// 	background: transparent;
		// }
	}
}

// MISC
////////////////////////////////////


.immo_tags {
	display: flex;
	width: calc(100% + 0.4rem);
	margin-left: -0.2rem;
	margin-right: -0.2rem;
	flex-wrap: wrap;

	body.openimmo-detail & {
		margin-bottom: rem($baseGap);
	}

	mark {
		flex-grow: 1;
		margin: 0.2rem;
		background: lighten($medium, 20%);
		color: $dark;
		padding: 0.7em;
		text-align: center;
		font-size: rem(12px);
		line-height: 1.3em;
		display: inline-block;

		&[data-mark="top angebot"], &[data-mark="rundgang"], &[data-mark="neu"] {
			background: lighten($medium, 10%);
			font-weight: bold;
		}
	}

	&.lowAmount {					
		mark {
			flex-grow: 0;
		}
	}
}

.immo_actions {
	.btn {
		cursor: pointer;
		margin: 0 rem($baseGap / 2) rem($baseGap / 2) 0;

		@include breakpoint(medium, max) {
			margin: 0 0 rem($baseGap / 2) 0;
			display: block;
		}
	}

	&.detail {
		.btn {
			width: 100%;
			margin: 0 0 rem(15px) 0;

			strong {
				font-weight: 700;
			}
			
			&.remove {
				background: #aa3434;
				color: #fff;
				
				&:hover, &:focus {
					background: #d95858;
					color: #fff;
				}
			}

			&.wait {
				cursor: wait !important;
				opacity: 0.5 !important;
				touch-action: none;
			}
		}
	}
}

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/