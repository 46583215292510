.huge-block {
	@include breakpoint(huge) {
		display: block;
	}
}

.notAllowed {
	cursor: not-allowed;
}

.fontSize22 {
	@include responsive-font-size(22px);
}

.uppercased {
	text-transform: uppercase;
}

.lettersSpaced {
	letter-spacing: 0.2em;
}

img[data-src][class*='keepRatio'] {
	&:not(.lazyLoaded) {
		height: 0;
		overflow: hidden;

		&.is75by53 {
			padding-top: 70.67%
		}
 	}
}

.objectFit {
	@include object-fit(cover, center center);
	max-width: none;
	display: block;

	.medium-6 & {
		@include breakpoint(medium) {
			height: 100%;
		}
	}
}

.linkHover, .linkHoverInvert {
    display: inline-block;
    transition: color 366ms ease-in-out;
    position: relative;
    line-height: 1.3;

    &:after {
        content: "";
        border-bottom: 1px solid;
        display: block;
        transition-property: opacity, transform;
        transition-duration: 366ms;
        transition-timing-function: ease-in-out;
        transform-origin: 0% 50%;
    }
}

.linkHover {
    &:after {
        transform: scaleX(0);
        opacity: 0;
    }

    &:hover, &:focus, &:active, &.active {
        &:after {
            transform: scaleX(1);
            opacity: 1;
        }
    }
}

.linkHoverInvert {
    &:after {
        transform: scaleX(1);
        opacity: 1;
    }

    &:hover, &:focus, &:active, &.active {
        &:after {
            transform: scaleX(0);
            opacity: 0;
        }
    }
}

.oneInputForm {
    display: flex;

    form.default & {
        .input {
            flex-grow: 1;
            display: flex;
            align-items: stretch;

            input {
                margin-bottom: 0;
                border-right: 0;
            }
        }

        button {
            width: auto;
			margin-bottom: 0;
			border-top: 0;
			border-left: 0;
			border-right: 0;

			&:hover, &:active, &:focus {
				border-left-color: $primary;
			}
		}
    }
}

%teaserMargin {
    // lets define how our inner teaser-margin (y-axis) should scale fluidly
    $teaserMarginYMap: (
        320px: $baseLineHeight * 2 - $baseLineHeight / 2.5,
        map-get($breakpointsPx, 'tiny'): $baseLineHeight * 2 - $baseLineHeight / 2,
		map-get($breakpointsPx, 'small'): $baseLineHeight * 2 - $baseLineHeight / 1.75,
		map-get($breakpointsPx, 'medium'): $baseLineHeight * 2 - $baseLineHeight / 1.5,
		map-get($breakpointsPx, 'large'): $baseLineHeight * 4.75 - $baseLineHeight / 1.25,
		map-get($breakpointsPx, 'giant'): $baseLineHeight * 4.75 - $baseLineHeight
	);

    // lets make our teaser-margin-top scale fluidly
    @include poly-fluid-sizing('margin-top', $teaserMarginYMap);
    // lets make our teaser-margin-bottom scale fluidly
    @include poly-fluid-sizing('margin-bottom', $teaserMarginYMap);
}

.highlight {
	color: $secondary;
}

.btn {
	@extend %animatedTransform;
	background: $primary;
	border: none;
	color: $light;
	cursor: pointer;
	font-style: italic;
	display: inline-block;
	font-size: 1em;
	padding: 1.25em 1.5em;
	text-align: center;
	border: 1px solid transparent;
	text-decoration: none;
	font-family: $mainFont;

	&:not(.noIcon) {
		&:before {
			@extend .icon;
			@extend .icon-angle-right;
			margin-right: 0.125em;
		}
	}

	&:hover,
	&:active,
	&:focus {
		background-color: $light;
		border-color: $secondary;
		color: $secondary;
	}

	&.isLight {
		background-color: $light;
		color: $dark;

		&:hover,
		&:active,
		&:focus {
			background-color: $primary;
			border-color: $light;
			color: $light;
		}
	}

	*[class^="icon"] {
		margin-right: 0.125em;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none;
}

.hideText {
	@include hideText();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.floatLeft {
	float: left;
}

img.floatLeft {
	margin-right: 1rem;
}

.floatRight {
	float: right;
}

img.floatRight {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.videoContainer {

	
	&:not(.videoTag)
	{
		position: relative;
		background-size:cover;
		background-position: center center;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;

		&.is21by9 {
			padding-bottom: 42.86%;
		}

		&.lazyLoaded {
			background-image:url('../images/layout/placeholder-video.jpg');
		}

		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
			top:0; 
			left:0; 
		}
	}

	video {
		width:100%;
		height:auto;
	}
}
.googleMaps {
	@extend .videoContainer;

	&.lazyLoaded {
		background-image:url('../images/layout/placeholder-maps.jpg')!important;
	}

	@include breakpoint(medium) {
		height: rem(400px);
	}
}

body {
	&:before {
	$content: "default:" + $bpContext;
	width: str_length($content) * 6pt + $baseGap * 2;

	@each $point, $width in $gridMap {
		$content: $content +
		"...." +
		$point +
		":" +
		nth(map-get(map-get($gridMap, $point), width), 1);

		@include breakpoint($point) {
		width: str_length($content) * 6pt + $baseGap * 2;
		}
	}

	content: "#{$content}";
	display: none !important; /* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
	background: $alert;
	border-color: $alert;
	border-style: solid;
	border-width: 0 rem($baseGap);
	color: $light;
	display: block !important;
	font-family: Courier;
	font-size: 10pt;
	left: 0;
	line-height: 2.5;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	white-space: nowrap;
	z-index: 99999;
	}
	&:hover:before {
	opacity: 0.5;
	pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}


.notificationArea
{
	position: fixed;
	bottom:20px;
	left:20px;
	z-index:999;

	> :not(:last-child)
	{
		margin-bottom:$baseGap;
	}

	.notification
	{
		position: relative;
		transition:300ms all ease-in-out;
		overflow: hidden;
		padding: 1em;
		background: $alert;
		font-size: 12px;
		line-height: 16px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
		width:330px;
		max-width:calc(100vw - 40px);
		transform-origin: left center;

		&, a
		{
			color: $light;
		}

		.close
		{
			position: absolute;
			top: 5px;
			right: 8px;
			cursor: pointer;
			color:#fff;
			font-size:1.2rem;
		}

		&.hide
		{
			opacity:0;
			visibility: hidden;
			transform:scale(0);
			max-height:0;
			height:0;
			padding-top:0;
			padding-bottom:0;
		}
	}
}