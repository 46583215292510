// Form styles
form.default {
	overflow: hidden;
	width: 100%;

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $primary;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $primary;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $primary !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $primary;
	}

	fieldset {
	    border: 1px solid rgba($dark, 0.125);
	    padding: rem($baseLineHeight) rem($baseGap);
	    margin: 0 0 3.5rem 0;
		width: 100%;
		transition: opacity 366ms ease-in-out;

		&:first-child:last-child {
			margin-bottom: 0;
		}

		&[disabled] {
			opacity: 0.5;

			.btn {
				pointer-events: none;
			}
		}
	}

	.medium-6 fieldset {
	    @include breakpoint(medium) {
	        margin-bottom: 0;
	    }
	}

	legend {
	    background-color: $primary;
	    color: $light;
	    font-size: 1em;
	    padding: rem($baseLineHeight / 4) rem($baseGap / 1.5);
	    border-left: rem($baseGap / 2) solid $light;
	    border-right: rem($baseGap / 2) solid $light;
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		min-height: 3.5rem;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;
			// position: absolute;
		}

		&[for="Datenschutz"] {
			@extend .marginBottom;
			float: left;
			width: calc(100% - #{rem(30px)});
		}
	}

	.inputField {
		position: relative;
		width: 100%;

		&:focus-within {
			&:after {
				opacity: 1;
				transform: none;
			}

			[type="text"],
			[type="tel"],
			[type="email"],
			[type="file"],
			input,
			textarea,
			select {
				border-bottom-color: transparent;
				transition: none;
			}
		}

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			border-bottom: 1px solid $dark;
			transform: scaleX(0);
			opacity: 0;
			transition-property: opacity, transform;
			transition-duration: 366ms;
			transition-timing-function: ease-in-out;
		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border: none;
		border-bottom: $baseBorder;
		color: $dark;
		display: block;
		font-size: em((90 * $baseFontSize) / 100, $baseFontSize);
		font-family: $mainFont;
		margin: 0 0 0.5em 0;
		padding: 0.6em 0.4em;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;
		transition: border-bottom-color 366ms ease-in-out;

		&:focus {
			border-bottom-color: $dark;
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 7.5rem;
		padding-top: 0.9rem;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 7.5rem * $inputgroup-count - 4rem;
		}
	}

	[type="checkbox"] {
		display: inline-block;
		float: right;
		margin: 0 0 0.5rem rem(15px);
		padding: 0;
		width: rem(15px);
		height: rem(15px);

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		clear: none;
		float: left;
		width: rem(15px);
		margin: 0.4rem 0.8rem 0 0;
		min-height: auto;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 0 1.75rem;
		width: 100%;
	}
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}