/**
* OpenImmo Module
* @author Kerem Agar
* @edit Jasmin Reiff
* @version 1.8.1
* @date March 03, 2020
*/

/**
* Place your custom styles for this project ONLY here 
* Tip: !important can become helpful in some cases :)
*/

.backToTop {
	// Due to the sidebar (If not used you don't need this)
	@include breakpoint(medium,max) {
		bottom: rem(75px);
	}
}

body.openimmo {
	.privacyControlBadge {
		z-index: 1;
	}
}


body.openimmo {
    #content {
        .immo_details {
            .immo_objectHeadline {
                font-size: rem(18px);
                line-height: rem(24px);
                span {
                    display: block;
                }
            }            
        }
        .immo_fastInfos {
            .immo_objectHeadline {
                font-size: rem(28px);
                line-height: rem(32px);
                span {
                    display: block;
                }
            }
        }
        .immo_bookmark  {
            background: $secondary;

            svg {
                path {
                    fill: transparent;
                    stroke: $light;
                }
            }
            &.active {
                svg {
                    path {
                        fill: $light;
                        stroke: transparent;
                    }
                }
            }
        }
    }
}

#immo_bookmarkJS {
    .title {
        font-size: rem(14px) !important;
        line-height: rem(24px) !important;
        text-transform: none !important;
        margin-bottom: 0 !important;
        letter-spacing: 0 !important;
    }
}

.marginBottom,
.margin-bottom,
.marginBottomMore {
    margin-bottom: rem($baseLineHeight / 3) !important;

	@include breakpoint(tiny) {
		margin-bottom: rem($baseLineHeight / 2) !important;
    }

    @include breakpoint(small) {
		margin-bottom: rem($baseLineHeight / 1.75) !important;
	}

	@include breakpoint(medium) {
		margin-bottom: rem($baseLineHeight / 1.5) !important;
	}

	@include breakpoint(large) {
		margin-bottom: rem($baseLineHeight / 1.25) !important;
	}

	@include breakpoint(giant) {
		margin-bottom: rem($baseLineHeight) !important;
	}
}


// .immo_newBadge {
// 	bottom: 0;
// 	background: $light;
// 	color: $dark;
// 	top: auto;
// }

// .immo_objectBox  {
// 	.immo_objectHeadline {
// 		color: $dark !important;
// 		font-size: rem(19px) !important;
// 		display: inline-block !important;
// 		width: 100% !important;
// 		margin: 0 0 1.4em 0 !important;
// 	}
// }

// #immo_detail {
// 	h2 {
// 		font-weight: 700;
// 	}
// 	.immo_objectHeadline {
// 		color: $dark !important;
// 		font-size: rem(26px) !important;
// 		line-height: rem(34px) !important;
// 		display: inline-block !important;
// 		width: 100% !important;
// 		margin: 0 0 1.4em 0 !important;
// 	}
// }