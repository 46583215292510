/**
* OpenImmo Module
* @author Kerem Agar
* @edit Jasmin Reiff
* @version 1.8.1
* @date March 03, 2020
*/

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/

.marginBottom,
.margin-bottom {
	margin-bottom: rem($baseLineHeight)!important;
}

.immo_marginBottom {
	margin-bottom: rem($baseLineHeight * 2)!important;
}

.end {
	justify-content: flex-end;
}

.middle {
	align-items: center;
}

.between {
	justify-content: space-between;
}

.bottom {
	align-items: flex-end;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter, .alignCenterAlways {
	text-align: center;
}

.baseGapped {
	max-width: calc(100% - #{rem($baseGap * 2)});
}

.wide {
	width: 100%;
	display: block;
}

.center {
	justify-content: center;
}

.around {
	justify-content: space-around;
}

.top {
	align-items: flex-start;
}

.relative {
	position: relative;
}

.middlefied {
	margin-left: auto;
	margin-right: auto;
	display: block;
}
  

body.openimmo main .col {
	@include breakpoint(default) {
		&.default-auto {
			max-width: 100%;
			flex-basis: 0;
		}
	}
	
	@include breakpoint(tiny) {
		&.tiny-6 {
			max-width: 50%;
			flex-basis: 50%;
		}
	}
	
	@include breakpoint(small) {
		&.small-6 {
			max-width: 50%;
			flex-basis: 50%;
		}
	}
	
	@include breakpoint(medium) {
		&.medium-prefix-2 {
			margin-left: 16.66666%;
		}
		&.medium-4 {
			max-width: 33.33333%;
			flex-basis: 33.33333%;
		}
		&.medium-5 {
			max-width: 41.66667%;
			flex-basis: 41.66667%;
		}
		&.medium-7 {
			max-width: 58.33333%;
			flex-basis: 58.33333%;
		}
		&.medium-8 {
			max-width: 66.66666%;
			flex-basis: 66.66666%;
		}
		&.medium-10 {
			max-width: 83.33333%;
			flex-basis: 83.33333%;
		}
	}
	
	@include breakpoint(large) {
		&.medium-prefix-2 {
			margin-left: 0;
		}

		&.large-3 {
			max-width: 25%;
			flex-basis: 25%;
		}
		&.large-4 {
			max-width: 33.33333%;
			flex-basis: 33.33333%;
		}
		&.large-8 {
			max-width: 66.66666%;
			flex-basis: 66.66666%;
		}
	}
	
	@include breakpoint(giant) {
		&.giant-prefix-1 {
			margin-left: 8.33333%;
		}
		&.giant-3 {
			max-width: 25%;
			flex-basis: 25%;
		}
		&.giant-6 {
			max-width: 50%;
			flex-basis: 50%;
		}
	}
	
	@include breakpoint(huge) {
		&.huge-8 {
			max-width: 66.66666%;
			flex-basis: 66.66666%;
		}
	}
	
	@include breakpoint(full) {
		&.full-3 {
			max-width: 25%;
			flex-basis: 25%;
		}
	}
	@include breakpoint(full) {
		&.full-4 {
			max-width: 33.33333%;
			flex-basis: 33.33333%;
		}
	}
}

@mixin space($space) {
	padding-top: #{rem($baseLineHeight * 2)};
	padding-bottom: #{rem($baseLineHeight * 1)};
	
	@if ($space < ($baseLineHeight * 3)) {
		@include breakpoint(small) {
			padding-top: rem($space);
			padding-bottom: rem($space - $baseLineHeight);
		}
	}
	
	@else {
		@include breakpoint(small) {
			padding-top: #{rem($baseLineHeight * 3)};
			padding-bottom: #{rem($baseLineHeight * 2)};
		}
		
		@if ($space < ($baseLineHeight * 5)) {
			@include breakpoint(giant) {
				padding-top: rem($space);
				padding-bottom: rem($space - $baseLineHeight);
			}
		}
		
		@else {
			@include breakpoint(giant) {
				padding-top: #{rem($baseLineHeight * 5)};
				padding-bottom: #{rem($baseLineHeight * 4)};
			}
			
			@include breakpoint(huge) {
				padding-top: rem($space);
				padding-bottom: rem($space - $baseLineHeight);
			}
		}
	}
}

.space {
	@include space(145px);
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
	-o-object-fit: $fit;
	   object-fit: $fit;
	@if $position {
		-o-object-position: $position;
		   object-position: $position;
		font-family: 'object-fit: #{$fit}; object-position: #{$position}';
	} @else {
		font-family: 'object-fit: #{$fit}';
	}
}

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/