/**
* OpenImmo Module
* @author Kerem Agar
* @edit Jasmin Reiff
* @version 1.8.1
* @date March 03, 2020
*/


/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/

// FIXED BUTTONS FOR SIDEBAR
////////////////////////////////////
#immo_fixedButtons {
    display: none;
    @include breakpoint(full) {
        // display: block;
        z-index: 500;
        position: fixed;
        right: rem($baseGap);
        bottom: 0px;
        width: 100%;
    }
    @include breakpoint(full) {
        transform: rotate(-90deg);
        transform-origin: right bottom;
        right: 0;
        top: 20%;
        width: auto;
        height: auto;
        bottom: auto;
    }
    ul {
        display: flex;
        display: none;
        width: auto;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
        @include breakpoint(full) {
            height: auto;
        }
        li {
            padding: 0;
            margin: 0 15px 0 0;
            display: block;
            z-index: 800;
            &:last-child {
                margin-right: 0;
            }
            label {
                line-height: 3em;
                font-size: 1rem;
                background: $light;
                color: $dark;
                cursor: pointer;
                padding: 0;
                transition: all .3s;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: rem(1.5px) rem(1.5px) rem(4px) rem(0px) rgba(0, 0, 0, 0.6);
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
                overflow: hidden;
                // @include breakpoint(giant) {
                //     line-height: 4em;
                // }
                &:hover,
                &:active,
                &:focus {
                    background-color: $light;
                    color: $secondary;
                }
                i {
                    width: 3em;
                    height: 3em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // @include breakpoint(giant) {
                    //     width: 4em;
                    //     height: 4em;
                    // }
                    svg {
                        width: 100%;
                        max-width: 1.35em;
                        @include breakpoint(full) {
                            transform: rotate(90deg);
                        }
                        // @include breakpoint(giant) {
                        //     max-width: 1.5em;
                        // }
                    }
                }
                span {
                    display: block;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    background: $light;
                    color: $dark;
                    font-size: rem(14px);
                    // @include breakpoint(full) {
                    //     font-size: rem(18px);
                    // }
                    // @include breakpoint(giant) {
                    //     padding-left: rem(45px);
                    //     padding-right: rem(45px);
                    //     font-size: rem(20px);
                    // }
                }
            }
            &.search {
                label {
                    span {
                        background: $primary;
                        color: $light;
                    }
                }
            }
            &.bookmark {
                span {
                    display: none;
                }
            }
        }
    }
}

// SIDEBAR
////////////////////////////////////
#fixSidebarSearch,
#fixSidebarBookmark {
    display: none;
}

#fixSidebarSearch:not(:checked) {
    ~#immo_sidebarSearch {
        transform: translateX(100%);
        >div {
            box-shadow: none;
        }
        .close {
            visibility: hidden;
            opacity: 0;
        }
    }
}

#fixSidebarBookmark:not(:checked) {
    ~#immo_sidebarBookmark {
        transform: translateX(100%);
        >div {
            box-shadow: none;
        }
        .close {
            visibility: hidden;
            opacity: 0;
        }
    }
}

.immo_sidebar {
    @extend %animatedTransform;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: rem(650px);
    z-index: 999 !important;
    height: 100%;
    @include breakpoint(medium) {
        height: auto;
        top: 13%;
        max-width: rem(450px);
    }
    @include breakpoint(giant) {
        top: 20%;
        max-width: rem(500px);
    }
    .close {
        @extend %animatedTransform;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 3000;
        background: $primary;
        width: rem(60px);
        height: rem(60px);
        border-bottom-left-radius: 5px;
        i {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            transition: all .2s ease-in-out;
            svg {
                width: 100%;
                max-width: rem(25px);
                * {
                    color: $light;
                    fill: $light;
                }
            }
        }
        span {
            display: none;
        }
        &:hover,
        &:focus {
            i {
                transform: scale(0.9);
            }
        }
        @include breakpoint(medium) {
            border-top-left-radius: 5px;
            right: 100%;
            left: auto;
        }
    }
    >.content {
        overflow-y: auto;
        height: 100%;
        padding: 2rem;
        background: $light;
        color: $dark;
        position: relative;
        box-shadow: rem(1.5px) rem(1.5px) rem(4px) rem(0px) rgba(0, 0, 0, 0.6);
        z-index: 2;
        @include breakpoint(medium) {
            padding: 3rem;
            border-bottom-left-radius: 5px;
            min-height: rem(600px);
        }
        h1 {
            font-size: rem(22px);
            font-weight: 500;
            @include breakpoint(cut) {
                font-size: rem(36px);
            }
        }
        .btn {
            width: 100%;
            &.immo_marginBottom {
                margin-bottom: .7rem !important;
            }
        }
        #immo_searchForm {
            &.default [type="text"],
            &.default [type="tel"],
            &.default [type="email"],
            &.default [type="file"],
            &.default input,
            &.default textarea,
            &.default select {
                display: block;
                font-size: 1rem;
                line-height: 1.88889rem;
                margin: 0 0 0.5rem 0;
                padding: 0.8rem;
                position: relative;
                resize: none;
                width: 100%;
            }
            fieldset {
                border: 0;
                padding: 0;
                margin: 0;
                label,
                legend {
                    display: none;
                }
                input {
                    width: 100%;
                }
            }
            .btn {
                margin: rem($baseGap) 0 0 0;
            }
        }
        h1,
        h2,
        h3 {
            span {
                display: block;
                font-size: 80%;
            }
        }
        #immo_bookmarkJS {
            .object {
                margin-bottom: rem(10px);
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                background: darken($light, 6%);
                position: relative;
                div {
                    width: 100%;
                    &.coverPhoto {
                        position: absolute;
                        top: 0;
                        left: 0;
                        @include breakpoint(tiny) {
                            width: 40%;
                            height: 100%;
                        }
                        img {
                            @include object-fit(cover, center center);
                            height: 100%;
                            // transform: translateY(rem($baseGap * -1));
                        }
                    }
                    &.info {
                        padding: rem($baseGap);
                        margin-left: 40%;
                        width: 60%;
                        height: 100%;
                        min-height: rem(150px);
                        display: flex;
                        justify-content: center;
                        flex-flow: column;
                        span {
                            font-size: 0.7rem;
                            line-height: 1.4rem;
                            color: $dark !important;
                            display: block;
                            span {
                                display: inline-block!important;
                            }
                            &.title {
                                @extend .h4;
                                font-size: 20px!important;
                                margin-bottom: 0.2em !important;
                            }
                        }
                    }
                }
            }
        } // #immo_bookmarkJS
    } // > .content
} // .immo_sidebar

/**
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
* Don't edit this file. 
* Place your changes in _custom.scss
* Your changes in this file will be LOST after an update
*
* WARNING ! WARNING ! WARNING ! WARNING !
*
*
*/