* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: $baseLineHeight / $baseFontSize;
  font-weight: 300;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;

  &.preload * {
    transition: none !important;
  }
}

body {
  opacity: 1 !important;
  font-size: rem($baseFontSize - 2px);

	@include breakpoint(tiny) {
		font-size: rem($baseFontSize - 1.5px);
	}

	@include breakpoint(small) {
		font-size: rem($baseFontSize - 1px);
	}

	@include breakpoint(large) {
		font-size: rem($baseFontSize - 0.5px);
	}

	@include breakpoint(giant) {
		font-size: rem($baseFontSize);
  }
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

.marginBottom {
	margin-bottom: rem($baseLineHeight / 3);

	@include breakpoint(tiny) {
		margin-bottom: rem($baseLineHeight / 2);
  }

  @include breakpoint(small) {
		margin-bottom: rem($baseLineHeight / 1.75);
	}

	@include breakpoint(medium) {
		margin-bottom: rem($baseLineHeight / 1.5);
	}

	@include breakpoint(large) {
		margin-bottom: rem($baseLineHeight / 1.25);
	}

	@include breakpoint(giant) {
		margin-bottom: rem($baseLineHeight);
	}
}

.defaultFullMarginBottom {
	margin-bottom: rem($baseLineHeight);
}

figure {
  figcaption {
    margin-top: 0.33em;
  }
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  font-family: $displayFont;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  // font-weight: 300;
  text-transform: uppercase;
  line-height: 1.3;
  // letter-spacing: 0.2em;
  letter-spacing: 0.05em;

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
  */

  span {
    display: block;
    letter-spacing: 0;
    text-transform: none;
    // font-weight: 600;
    margin-top: -0.125em;
  }
}

h1,
.h1 {
  @include responsive-font-size($h1Size);
  // letter-spacing: 0.15em;
  letter-spacing: 0.05em;

  span {
    font-size: em(34px, $h1Size);
    color: $primary;
  }
}

h2,
.h2 {
  @include responsive-font-size($h2Size);

  span {
    font-size: em(28px, $h2Size);
    color: $primary;
  }
}

h3,
.h3 {
  @include responsive-font-size($h3Size);

  span {
    font-size: em(26px, $h3Size);
    color: $primary;
  }
}

h4,
.h4 {
  @include responsive-font-size($h4Size);
}

h5,
.h5 {
  @include responsive-font-size($h5Size);
}

h6,
.h6 {
  font-size: rem(16px);

  @include breakpoint(full) {
    @include responsive-font-size($h6Size);
  }


}

/**
 * Links
 */
a {
  color: $primary;
  word-wrap: break-word;
  text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    color: $secondary;
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr, .hr {
	background: none;
	border: none;
	clear: both;
	height: rem(1px);
	flex-basis: 100%;
	position: relative;
	overflow: visible;
	width: 100%;
	float: left;
	margin: rem($baseLineHeight / 2 - ($baseLineHeight / 3)) auto rem($baseLineHeight / 2);

	@include breakpoint(tiny) {
		margin: rem($baseLineHeight / 1.5 - ($baseLineHeight / 2)) auto rem($baseLineHeight / 1.5);
	}

	@include breakpoint(small) {
		margin: rem($baseLineHeight - ($baseLineHeight / 1.75)) auto rem($baseLineHeight);
	}

	@include breakpoint(medium) {
		margin: rem($baseLineHeight * 1.6 - ($baseLineHeight / 1.5)) auto rem($baseLineHeight * 1.6);
	}

	@include breakpoint(large) {
		margin: rem($baseLineHeight * 1.6 - ($baseLineHeight / 1.25)) auto rem($baseLineHeight * 1.6);
	}

	@include breakpoint(giant) {
		margin: rem($baseLineHeight * 1.6 - ($baseLineHeight)) auto rem($baseLineHeight * 1.6);
	}

	&:after {
		content: "";
		border-bottom: 1px solid #808080;
    width: 100%;
		max-width: rem(116px);
		transform: translate3d(-50%, 0, 0);
		position: absolute;
		top: 50%;
		left: 50%;
	}

	&.tilSmall {
		@include breakpoint(small) {
			display: none;
		}
	}

	&.tilMedium {
		@include breakpoint(medium) {
			display: none;
		}
	}

	&.tilGiant {
		@include breakpoint(giant) {
			display: none;
		}
	}

	&.fromSmallTilGiant {
		display: none;

		@include breakpoint(small) {
			@include breakpoint(giant, max) {
				display: block;
			}
		}
	}

	&.noFloat {
		float: none;
	}

	&.evenMargin {
		margin: rem($baseLineHeight) auto;
	}

	&.evenMarginHalf {
		margin: rem($baseLineHeight / 2) auto;
	}

	&.fullWidth {
		&:after {
			max-width: none;
		}
	}

	&.isLight:after {
		border-bottom-color: rgba($light, 0.25);
	}

	&.isLeftAligned:after {
		left: 0;
		transform: none;
	}

	&.infinite {
		&:after {
			@include breakpoint(huge) {
				width: auto;
				left: calc(50% - 50vw);
				right: calc(50% - 50vw);
				transform: none;
			}
		}
	}

	&.invisible {
		&:after {
			display: none;
		}
	}
}

ul,
ol {
  list-style: none;

  &.styledList {
    &.dividedList {
      column-count: 2;
      column-gap: rem($baseGap);
  
      @include breakpoint(medium) {
        @include breakpoint(giant, max) {
          column-count: 0;
        }
      }
  
      li {
        break-inside: avoid-column;
      }
    }

    li {
      padding-left: rem(15px);
      margin-bottom: rem(13px);

      &:before {
        @extend .icon;
        @extend .icon-angle-right;
        text-indent: rem(-15px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}
