.videoPopUp {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100vh;
	background:rgba(black,0.8);
	z-index: 10000;
	transition:opacity 0.3s, visibility 0.3s, transform 0.3s, z-index 0.3s;

	body.internetExplorer &  {
		display: flex;
		justify-content: inherit;
	}

	&:not(.active) {
		opacity: 0;
		visibility: hidden;
		transform:scale(0);
		z-index: -999;
	}

	.popUpContainer {
	    overflow: hidden;
	    padding-bottom: 0;
	    width: 550px;
	    height: 300px;
	    margin: 0 auto;

	    iframe {
    	    height: 100%;
	    }
	}
}

.popUpClose{
	position: absolute;
	top:rem(40px);
	right:rem(50px);
	color:$light;
	font-size: rem(40px);
	border:none;
	background:none;
	transition:color 0.3s;

	&:hover {
		cursor: pointer;
		color:$primary;
	}
}

.infoPopUp {
 
	body:not(.cmsBackend) & {
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		overflow: hidden;
		transition: opacity .3s, visibility .3s;
		position: fixed;
		visibility: visible;
		opacity: 1;
		z-index: 1000000;
 
		.popUpContent {
            width: 100%;
			background: $light;
			max-height: 90vh;
			max-width: calc(100% - #{rem(110px)}); // for the close label
	    	overflow: hidden;
	        overflow-y: auto;
	        padding: rem($baseGap / 2);

	        @include breakpoint(tiny) {
	        	padding: rem($baseGap * 2);
	        }
	 
			@include breakpoint(giant) {
				max-width: 80%;
			}
	 
			@include breakpoint(full) {
				max-width: 70%;
			}
		}
	}
 
	label {
		display:none;
 
		body:not(.cmsBackend) & {
			background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			padding: rem($baseGap);
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(35px);
				width: rem(35px);
				position: relative;
				transition: background .3s;
	 
				&:hover {
					background: rgba(black, 0.8);
	 
					&:before, &:after {
						background: white;
					};
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(30px);
					height: rem(2px);
					background: #ddd;
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
}
 
input[id^="popUpToggled"] {
	display: none;
 
	&:not(:checked) {
 
		+ {
 
			.infoPopUp {
				position: relative;
				opacity: 0;
				visibility: hidden;
				z-index: -1000000;
				transform:scale(0);
				pointer-events: none;
				width: 0;
				height: 0;
			}
		}
	}
}