// text
$colorMain: #fff;
$colorMedium: #eee;
$colorLink: #eee;
$colorDark: #464a4d;
// backgrounds
$bgFields: #464a4d;
$bgMain: #333;
$bgSubmit: #3bb54a;
$bgCheckbox: #888a8c;
$bgCheckSlider: #fff;
// misc
$borderStroke: 2px;
$spacing: 10px;
$borderRadius: 4px;
$fontSize: 11px;
// overwrite default notification styles
#privacyCtrl {
    background: $bgMain;
    border-radius: $borderRadius;
    color: $colorMain;
    padding: 0;
    &,
    * {
        font-family: Arial, sans-serif;
    }
    &.hide,
    .hide {
        opacity: 0;
        visibility: hidden;
    }
    &.open {
        opacity: 1;
        visibility: visible;
    }
    .open & {
        height: calc(100vh - 40px);
    }
}

// main styles
#privacy-controller {
    display: block;
    font-size: $fontSize;
    line-height: 1.3em;
    font-weight: normal;
}

.privacy-header {
    display: block;
    padding: $spacing;
    .privacy-text {
        padding-right: $spacing * 2;
    }
}

.privacy-text {
    display: block;
    margin-bottom: $spacing;
}

.privacy-buttons {
    display: flex;
}

.privacy-btn {
    width: 100%;
    display: flex;
    color: $colorDark;
    background: $bgCheckbox;
    cursor: pointer;
    border-radius: $borderRadius;
    padding-top: $spacing/1.5;
    padding-bottom: $spacing/1.5;
    padding-left: 12px;
    padding-right: 12px;
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: center;
    &:first-of-type {
        margin-right: $spacing;
    }
}

#privacy-confirm {
    background: $bgSubmit;
    color: $colorMain;
}

.privacy-link {
    cursor: pointer;
    color: $colorLink;
    text-decoration: underline;
}

.privacy-fields {
    display: block;
    background: $bgFields;
    max-height: 40vh;
    overflow-y: auto;
    border-left: $borderStroke solid $bgMain;
    border-right: $borderStroke solid $bgMain;
    height: 0;
    transition: 800ms height ease-in-out;
    .privacy-controller.open & {
        height: 100vh;
    }
    label {
        cursor: pointer;
        position: relative;
        display: block;
        width: 100%;
        span {
            font-size: 1.2em;
            content: "";
            letter-spacing: 0;
            width: 2.2em;
            height: 1.2em;
            line-height: 1.2em;
            border-radius: 14px;
            display: block;
            text-align: center;
            background: $bgCheckbox;
            color: transparent;
            margin-right: $spacing;
            position: relative;
            &:before {
                content: "";
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: $bgCheckSlider;
                position: absolute;
                left: 4px;
                top: 3px;
                opacity: 1;
                transition: 150ms left ease-in-out;
                z-index: 10;
            }
            &:after {
                content: attr(data-off) " ";
                display: inline-block;
                position: absolute;
                left: calc(100% + 5px);
                color: $colorMedium;
                font-size: rem($fontSize);
                word-break: normal;
            }
        }
    }
    input:checked+span:before {
        left: rem(17px);
    }
    input:checked+span {
        background: $bgSubmit;
        &:after {
            content: attr(data-on) " ";
        }
    }
    input {
        display: none;
    }
}

.privacy-row {
    display: flex;
    flex-wrap: wrap;
    padding: $spacing;
    &:not(:last-of-type) {
        border-bottom: $borderStroke solid $bgMain;
    }
}

.privacy-cell {
    width: 50%;
    hyphens: auto;
    &:not(:last-of-type) {
        margin-bottom: $spacing;
    }
}

.privacy-footer {
    display: flex;
    justify-content: flex-end;
    padding: $spacing;
    .privacy-link {
        padding-left: $spacing;
    }
}

#privacy-reset {
    opacity: 0.5
}

// independent badge
.privacyControlBadge {
    body.privacyControlIsOpen & {
        left: -100%;
    }
    &:hover,
    &:active,
    &:focus {
        background-color: $primary;
        color: $light;
    }
    z-index: 100;
    // transform: rotate(90deg) translateX(-100%);
    display: inline-block;
    position: fixed;
    left: 0;
    // bottom: 45px;
    bottom: 2px;
    transform-origin: bottom left;
    background: $bgMain;
    color: $colorMain;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    font-size: rem(12px);
    line-height: rem(12px);
    text-decoration: none;
    padding: $spacing;
    transition: 300ms all ease-in-out;
}